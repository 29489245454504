// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";  // Si vas a usar Firestore
import { getAuth } from "firebase/auth";            // Si vas a usar Auth

// Tu configuración de Firebase (copiada desde Firebase Console)
const firebaseConfig = {
    apiKey: "AIzaSyBbyOs8wu99JxqldO-5pr4-aw5ZPSkAuNs",

    authDomain: "cementeriomercedes-ca91e.firebaseapp.com",
  
    databaseURL: "https://cementeriomercedes-ca91e-default-rtdb.firebaseio.com",
  
    projectId: "cementeriomercedes-ca91e",
  
    storageBucket: "cementeriomercedes-ca91e.appspot.com",
  
    messagingSenderId: "480462652434",
  
    appId: "1:480462652434:web:0b8b7e91461587ddc444cb",
  
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);

// Inicializa servicios de Firebase si los vas a utilizar
export const db = getFirestore(app);
export const auth = getAuth(app);

export default app;
