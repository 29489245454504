import React from 'react';
import './App.css';
import app from './firebase'; // Importa Firebase

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>React + Firebase en JavaScript</h1>
        <p>Tu app de Firebase está lista.</p>
      </header>
    </div>
  );
}

export default App;
